// Generated by Framer (f558e44)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ep5pPy7Oc", "cpOh9gwNf", "H_vIdKxjm", "z6z3WQoCd", "mi3qXojT2"];

const serializationHash = "framer-3wbGK"

const variantClassNames = {cpOh9gwNf: "framer-v-1c8r2y6", ep5pPy7Oc: "framer-v-ix1y59", H_vIdKxjm: "framer-v-8e6yt5", mi3qXojT2: "framer-v-1ny8bna", z6z3WQoCd: "framer-v-prfrmh"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Brand Logo": "ep5pPy7Oc", "Header Logo Light": "mi3qXojT2", "Logo - Light Small": "z6z3WQoCd", "Logo Dark": "cpOh9gwNf", "Primary - Small": "H_vIdKxjm"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ep5pPy7Oc"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ep5pPy7Oc", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-ix1y59", className, classNames)} data-framer-name={"Brand Logo"} initial={variant} layoutDependency={layoutDependency} layoutId={"ep5pPy7Oc"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({cpOh9gwNf: {"data-framer-name": "Logo Dark"}, H_vIdKxjm: {"data-framer-name": "Primary - Small"}, mi3qXojT2: {"data-framer-name": "Header Logo Light"}, z6z3WQoCd: {"data-framer-name": "Logo - Light Small"}}, baseVariant, gestureVariant)}><Link href={{webPageId: "QQwekMhSz"}} openInNewTab={false} {...addPropertyOverrides({H_vIdKxjm: {href: {hash: ":kkXuIhlYo", webPageId: "QQwekMhSz"}}}, baseVariant, gestureVariant)}><Image as={"a"} background={{alt: "", fit: "fit", intrinsicHeight: 181, intrinsicWidth: 419, pixelHeight: 181, pixelWidth: 419, positionX: "center", positionY: "center", src: "https://framerusercontent.com/images/BvvTJLBK42oV1TLsv07gjDizs.png"}} className={"framer-svs2qb framer-1udp4rg"} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"g5K782cY0"} {...addPropertyOverrides({cpOh9gwNf: {background: {alt: "", fit: "fit", intrinsicHeight: 181, intrinsicWidth: 419, pixelHeight: 181, pixelWidth: 419, positionX: "center", positionY: "center", src: "https://framerusercontent.com/images/7Skky3YkNK3d9BRkn1ULbMJKeCk.png"}}, mi3qXojT2: {background: {alt: "", fit: "fit", intrinsicHeight: 181, intrinsicWidth: 419, pixelHeight: 181, pixelWidth: 419, positionX: "center", positionY: "center", src: "https://framerusercontent.com/images/lWWfqUiffQVBF97bKlpQx9kQdO8.svg"}}}, baseVariant, gestureVariant)}/></Link></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-3wbGK[data-border=\"true\"]::after, .framer-3wbGK [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-3wbGK.framer-1udp4rg, .framer-3wbGK .framer-1udp4rg { display: block; }", ".framer-3wbGK.framer-ix1y59 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 5px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-3wbGK .framer-svs2qb { flex: none; height: 56px; overflow: visible; position: relative; text-decoration: none; width: 130px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-3wbGK.framer-ix1y59 { gap: 0px; } .framer-3wbGK.framer-ix1y59 > * { margin: 0px; margin-left: calc(5px / 2); margin-right: calc(5px / 2); } .framer-3wbGK.framer-ix1y59 > :first-child { margin-left: 0px; } .framer-3wbGK.framer-ix1y59 > :last-child { margin-right: 0px; } }", ".framer-3wbGK.framer-v-1c8r2y6 .framer-svs2qb { height: 51px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 56
 * @framerIntrinsicWidth 130
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"cpOh9gwNf":{"layout":["auto","auto"]},"H_vIdKxjm":{"layout":["auto","auto"]},"z6z3WQoCd":{"layout":["auto","auto"]},"mi3qXojT2":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Frameri3VSz_7Je: React.ComponentType<Props> = withCSS(Component, css, "framer-3wbGK") as typeof Component;
export default Frameri3VSz_7Je;

Frameri3VSz_7Je.displayName = "Original Logo";

Frameri3VSz_7Je.defaultProps = {height: 56, width: 130};

addPropertyControls(Frameri3VSz_7Je, {variant: {options: ["ep5pPy7Oc", "cpOh9gwNf", "H_vIdKxjm", "z6z3WQoCd", "mi3qXojT2"], optionTitles: ["Brand Logo", "Logo Dark", "Primary - Small", "Logo - Light Small", "Header Logo Light"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Frameri3VSz_7Je, [])